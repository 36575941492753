/* Animation de l'effet de frappe pour le texte */
@keyframes typing {
    0% {
      width: 0;
    }
    100% {
      width: 58%;
    }
  }
  
  .accueil {
    position: relative;
    width: 100%;
    height: 100vh;  /* Prendre toute la hauteur de l'écran */
    background-image: url('../../assets/fonds/fond1.jpg'); /* Remplacer par l'image souhaitée */
    background-size: cover;
    background-position: center;
  }
  
  .accueil-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Overlay noir avec opacité */
  }
  
  .accueil-content {
    color: white;
  }
  
  .brand-name {
    color: #ffcc00cb;  /* Couleur de texte pour le nom de la marque */
    margin-bottom: -15px;
    font-weight: bold;
    display: inline-block;
    width: 0;  /* Commencer l'animation avec une largeur de 0 */
    overflow: hidden;
    white-space: nowrap;
    border-right: 4px solid #ffcc00cb;  /* Curseur de type "tapeur" */
    animation: typing 2s steps(16) 1s forwards;  /* Animation avec 16 étapes */
    animation-fill-mode: forwards;  /* L'animation s'arrête après la fin */
  }
  
  /* Style pour le bouton */
  a.btn {
    color: #ffcc00cb;  /* Couleur du texte du bouton */
    background-color: transparent;  /* Fond transparent */
    border: 2px solid #ffcc00cb;  /* Bordure de couleur de la marque */
    padding: 10px 20px;
    font-size: 1.25rem;
    text-transform: uppercase;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  a.btn:hover {
    background-color: #ffcc00cb;  /* Fond jaune lors du survol */
    color: #fff;  /* Texte blanc lors du survol */
    font-weight: bold;
  }

  @media (max-width: 700px) {
    .brand-name {
      border-right: 2px solid #ffcc00cb; /* Réduire la bordure pour s'adapter */
      animation: typing 2s steps(10) 1s forwards; /* Ajuster le nombre d'étapes */
    }
  }

  @media (max-width: 646px) {
    @keyframes typing {
      0% {
        width: 0;
      }
      100% {
        width: 43%;
      }
    }

    .accueil-content h1 {
      align-items: center;
      display: flex;
      font-size: 1.5rem;
      flex-direction: column;
    }

    .accueil-content p {
      font-size: 16px;
      margin: 0 20px;
      padding-bottom: 15px;
    }
  
    a.btn {
      font-size: 0.8rem; /* Ajuster la taille du bouton */
      padding: 5px 10px;
    }
    .brand-name {
      animation: typing 2s steps(16) 1s forwards; /* Ajuster le nombre d'étapes */
    }
  }

  @media (max-width: 505px) {
    @keyframes typing {
      0% {
        width: 0;
      }
      100% {
        width: 50%;
      }
    }
  }
  