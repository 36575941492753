/* Services.css */

.services-section {
    background-color: #f9f9f9;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .services-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    transition: transform 0.8s ease-in-out;
  }
  
  .services-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 40px;
    transition: transform 0.8s ease-in-out;
  }
  
  .service-card {
    background-color: white;
    transition: transform 0.3s ease;
  }
  
  .service-card .service-icon {
    font-size: 2.5rem;
    color: #007bff;
  }
  
  .service-card .card-title {
    font-weight: bold;
  }
  
  .service-card .card-text {
    color: #555;
  }
  
  /* Hover effet */
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  }
  

/* Styles personnalisés pour les indicateurs du carousel */
.carousel-indicators {
    margin-bottom: -20px !important; /* Ajustez la valeur selon vos besoins */ 
}

/* Forcer les indicateurs à être ronds */
.carousel-indicators button {
    width: 10px !important;  /* Forcer la largeur */
    height: 10px !important; /* Forcer la hauteur */
    background-color: #666 !important; /* Forcer la couleur */
    border-radius: 50% !important; /* Forcer les bords arrondis */
    border: none !important; /* Retirer la bordure par défaut */
    margin: 0 2px !important; /* Espacement entre les cercles */
    cursor: pointer; /* Ajout du curseur pointeur au survol */
    padding: 0 !important; /* Retirer tout padding */
    transition: background-color 0.3s ease; /* Transition douce */
}
  
  .carousel-indicators .active {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Légère lueur pour l'indicateur actif */
  }
  

