/* Style général */
.contact-section {
    background-color: #f9f9f9;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .contact-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
    transition: transform 0.8s ease-in-out;
  }
  
  .contact-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 40px;
    transition: transform 0.8s ease-in-out;
  }

  .contact-grid-border{
    border-right: 1px solid #3333331f;
  }
  
  /* Style des éléments de contact */
  .contact-info-item {
    color: #333;
    margin: 0 auto;
    max-width: 250px;
  }

  .contact-info-item h5{
    font-weight: bold;
  }
  
  .contact-icon {
    font-size: 2rem;
    color: #ffcc00ec;
    margin-bottom: 1rem;
  }
  
  /* Style du formulaire */
  .contact-form {
    background-color: white;
    box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.041);
    border-radius: 5px;
  }
  
  .contact-form .form-group label {
    font-weight: bold;
  }
  
  .contact-form .form-control {
    border: 1px solid #ddd;
  }

  .contact-form input:focus,
  .contact-form textarea:focus {
    box-shadow: 0 0 5px rgba(255, 166, 0, 0.7); 
    border-color: rgba(255, 166, 0, 0.5);; 
  }
  
  .contact-form .btn-form {
    background-color: rgba(255, 166, 0, 0.7);
    color: #333;
    text-align: center;
    padding: 5px 10px;
    font-size: 1.1rem;
    font-weight: 600; 
  }

  .contact-form .btn-form:hover {
    background-color: rgba(255, 166, 0, 0.568);
  }


  