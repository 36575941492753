/* Style pour la transition de fond du header */
.header {
  transition: background-color 0.3s ease;
  padding: 5px 30px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #ffcc00cb !important
}

.navbar-nav .nav-link {
  font-size: 16px;
  position: relative;             /* Nécessaire pour le pseudo-élément ::before */
  text-decoration: none;
  padding: 6px 0;
  transition: color 0.3s ease;    /* Transition pour le changement de couleur */
}

/* Animation de la bordure en bas des liens */
.navbar-nav .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  border-radius: 5px;
  background-color: #ffcc00cb;
  transition: width 0.3s ease;    /* Animation de la largeur */
}

.navbar-nav .nav-link:hover {
  font-weight: bold;
}

.navbar-nav .nav-link:hover::before {
  width: 100%;                    /* Fait s'étendre la bordure sur toute la largeur */
}

.navbar-toggler-icon {
  color: white;
}

/* Gérer les espaces entre les liens de navigation */
.navbar-nav {
  gap: 10px;
}
