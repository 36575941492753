/* BackToTop.css */

/* Style du bouton Back to Top */
.back-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 10%;
    padding: 0 2px;
    /* font-size: 5px; */
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1000;
  }
  
  .back-to-top.visible {
    opacity: 1;
  }
  
  .back-to-top:hover {
    background-color: #0056b3;
  }
  