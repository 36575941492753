.team {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #f8f9fa;
  }

  .team-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    transition: transform 0.8s ease-in-out;
  }
  
  .team-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 40px;
    transition: transform 0.8s ease-in-out;
  }
  
  .team-title {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .team-member {
    display: flex;
    align-items: center;
    padding: 0.1rem 1.2rem;
    position: relative;
    border-radius: 10px;
    border-left: 5px solid #007bff;
  }
  
  .team-member-info {
    max-width: 60%;
    padding-right: 20px;
    text-align: left;
  }
  
  .team-member-description {
    font-style: italic;
    color: #000000ee;
    position: relative;
    font-size: medium;
  }
  
  
  .team-member-name {
    color: #333333dc;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .team-member-profession {
    color: #6c757d;
    font-size: 1.1rem;
  }
  
  .team-member-photo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
  
  .team-member-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    /* border: 4px solid #007bff; */
  } 

  .quote-icon-left, .quote-icon-right {
        font-size: 1.5rem;
        color: #007bff96;
        line-height: 0;
        display: inline-block;
        position: relative;
    }

 .quote-icon-left {
        left: -5px;
    }

 .quote-icon-right {
        right: -5px;
        top: 10px;
        transform: scale(-1, -1);
    }
    