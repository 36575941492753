/* Section principale */
.about-section {
    background-color: #f9f9f9;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  /* Titres */
  .about-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    transition: transform 0.8s ease-in-out;
  }
  
  .about-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 40px;
    transition: transform 0.8s ease-in-out;
  }
  
  /* Description */
  .about-description {
    font-size: 1rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Image */
  .about-image {
    max-width: 100%;
    height: auto;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    transition: transform 0.3s ease;
  }
  
  .about-image:hover {
    transform: scale(1.05); /* Zoom léger au survol */
  }
  