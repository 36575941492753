/* Footer global style */
.footer {
  background-color: #333;
  color: white;
}

/* Contenu du footer */
.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 30px;
}

/* Section de footer */
.footer-section {
  flex: 1;
  margin: 10px;
}

/* Titre des sections */
.footer-section h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #f4f4f4;
  font-weight: bold;
}

/* Liens dans le footer */
.footer-section p, .footer-section ul{
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
}

/* Liens des sections */
.footer-section ul li{
  margin: 5px 0;
  color: white;
  text-decoration: none;
  font-size: 14px;
}

/* Hover sur les liens sociaux */
.footer-section .social-icons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;             /* Largeur du cercle */
  height: 30px;            /* Hauteur du cercle */
  border: 0;
  border-radius: 50%;      /* Rend l'icône circulaire */
  transition: box-shadow 0.3s ease; /* Animation de l'ombre lors du survol */
}

.footer-section .social-icons a:hover {
  box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.4); /* Ombre circulaire */
  background-color: rgba(255, 255, 255, 0.3);        /* Couleur de fond légère au survol */
}

/* Footer bas */
.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

/* Responsive */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin: 20px 0;
  }
}

.footer-section.contact p {
  margin: 5px 0;
  font-size: 14px;
}

.footer-section.contact a {
  color: white;
  text-decoration: none;
}

.footer-section.contact a:hover {
  color: #ddd;
}

/* Style du formulaire de newsletter */
.newsletter-form form {
  display: flex;               /* Utilise flexbox pour aligner les éléments sur une ligne */
  justify-content: space-between; /* Pour espacer les éléments de manière égale */
  width: 100%;                  /* Assure que le formulaire occupe toute la largeur disponible */
}

.newsletter-form .form-control {
  flex-grow: 1;                 /* Permet à l'input d'occuper l'espace restant */
  margin-right: 0;              /* Retire toute marge à droite */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.newsletter-form .btn {
  margin-left: 0;               /* Retire la marge à gauche du bouton */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Assurez-vous que la section des icônes de réseaux sociaux est bien séparée du formulaire */
.footer-section.social .social-icons {
  margin-top: 15px;
}
