/* Style de la bannière */
.cta-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Ajustez la hauteur selon vos besoins */
    background-image: url('../../assets/fonds/fond2.jpg'); /* Chemin de votre image de bannière */
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Image de fond fixée */
    text-align: center;
    position: relative;
    color: white;
  }
  
  .cta-banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Overlay noir avec opacité */
  }
  
  /* Contenu de la bannière */
  .cta-content {
    color: #ffffffd5;
  }

  .cta-content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .cta-button {
    background-color: transparent !important; /* Couleur du bouton */
    color: #ffffffe8 !important;
    border: 2px solid #ffffffe8 !important;
    padding: 10px 20px !important;
    font-size: 1rem !important;
    cursor: pointer !important;
    text-transform: uppercase;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #ffffffe8 !important; /* Couleur du bouton au survol */
    color: #1d1d1de1 !important;
    font-weight: bold;
  }
  
  