.portfolio-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
}


.portfolio-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    transition: transform 0.8s ease-in-out;
}
  
.portfolio-subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 15px;
    transition: transform 0.8s ease-in-out;
}

.portfolio-filter {
  background-color: #333;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.filter-btn {
  background-color: #333333bb;
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.filter-btn.active,
.filter-btn:hover {
  background-color: #555;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  
}

/* .portfolio-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
} */

.portfolio-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  height: 300px; /* Fixe une hauteur uniforme */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.portfolio-image-wrapper {
  overflow: hidden;
  position: relative;
  height: 100%; /* La hauteur du wrapper suit la carte */
}

.portfolio-image {
  width: 100%;
  height: 100%; /* L'image s'adapte à la hauteur fixée */
  object-fit: cover; /* Garde les proportions tout en remplissant l'espace */
  transition: transform 0.3s ease;
}

.portfolio-image-wrapper:hover .portfolio-image {
  transform: scale(1.1); /* Effet zoom avant */
}

.portfolio-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow: hidden; /* Empêche le contenu de dépasser */
}

.portfolio-image-wrapper:hover .portfolio-overlay {
  opacity: 1;
}

.portfolio-card-title {
  font-size: 18px;
  font-weight: bold;
}

.portfolio-card-category {
  font-size: 14px;
  color: #ccc;
}
